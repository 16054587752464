:root {
  --primary-background-color: #F4C2C2;
}

html {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  padding: 0;
  margin: 0;

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */

}

html::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
}