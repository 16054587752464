.navbar {
    position: fixed;
    top: 0;
    display: grid;
    grid-template-rows: 60px;
    grid-template-columns: 75% auto auto;
    background-color: var(--primary-background-color);
    background: linear-gradient(180deg, #f9e9e9 0%, var(--primary-background-color) 40%);
    margin: 0;
    width: 100%;
    min-height: 60px;
    font-size: 20pt;
    align-items: center;
    justify-content: center;
}

.title {
    float: left;
    padding-left: 50px;
    font-weight: bold;
}

.nav-button-container {
    margin: 10px;
    min-width: 105px;
}

.nav-button-container a {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
}

.nav-button-container a:visited, a:active, a:link {
    color: black;  
    text-decoration: none;
}

.nav-button-container a:hover {
    color: gray;
    text-decoration: solid;
}

@media screen and (max-width: 400px) {
    .nav-button-container {
        display: none;
    }
}